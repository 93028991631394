import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Mail, Phone, MapPin } from './Icons';

const contactNormal = css`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.66em;
`;

const contactBig = css`
  margin-bottom: 1.2em;
  font-size: 1.2em;
  font-weight: 600;
`;

const Contact = styled.span`
  margin-bottom: 1em;
  padding-left: 2em;
  font-size: 1.1em;
  font-weight: 500;
  position: relative;
  display: block;
  ${p => (p.footer && contactNormal) || p.theme.minWidth.tablet(contactBig)};
`;

const icon = css`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Title = styled.h2`
  font-size: 2rem !important;
  margin-bottom: 1em !important;
  ${p =>
    p.footer &&
    css`
      font-size: 1.5rem !important;
    `};
`;

export default ({ footer, title, phone, email, street, city }) => {
  return (
    <>
      <Title footer={footer}>{title}</Title>
      <Contact footer={footer}>
        <Phone css={icon} size={footer && 20} />
        <p>{phone}</p>
      </Contact>
      <Contact footer={footer}>
        <Mail css={icon} size={footer && 20} />
        <p>{email}</p>
      </Contact>
      <Contact footer={footer}>
        <MapPin css={icon} size={footer && 20} />{' '}
        <span>
          {street}
          <br />
          {city}
        </span>
      </Contact>
    </>
  );
};
