import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import BanerTitle from './BanerTitle';

export const FullWidthContainer = styled.div`
  width: 100vw;
  height: 400px;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 5em -50vw;
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.top &&
    css`
      margin-top: ${props.theme.navbarHeight};
    `}
`;

export const Hero = styled(Img)`
  position: absolute !important;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const ImageBaner = ({ image, title, top }) => (
  <FullWidthContainer top={top}>
    <Hero fluid={image.childImageSharp.fluid} />
    {title && <BanerTitle>{title}</BanerTitle>}
  </FullWidthContainer>
);

ImageBaner.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string
};

ImageBaner.defaultProps = {
  title: ''
};

export default ImageBaner;
