import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const wrap = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const HTMLContent = ({ content, className }) => (
  // eslint-disable-next-line react/no-danger
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} css={wrap} />
);

const Content = ({ content, className }) => (
  <div className={className} css={wrap}>
    {content}
  </div>
);

Content.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
