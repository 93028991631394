import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import SEO from './SEO';
import GlobalStyle from './GlobalStyle';
import '../../styles/app.sass';

const Layout = ({ children, customSEO }) => {
  return (
    <>
      <GlobalStyle />
      {!customSEO && <SEO />}
      <Navbar />
      <ScrollToTop />
      {children}
      <Footer />
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  customSEO: PropTypes.bool
};

Layout.defaultProps = {
  customSEO: false
};
