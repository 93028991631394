import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Button = styled(Link)`
  border-radius: 0;
  background-color: #549748 !important;
  border-color: transparent;
  color: white !important;
  margin-bottom: 1.5em;
`;

export default Button;
