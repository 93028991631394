import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import ContactInfo from '../ContactInfo';
import Link from '../Link';
import { Facebook, Instagram } from './Icons';
import { menu, copyright, author, authorLink } from '../../../../config/website';

const MainFooter = styled.footer`
  padding: 3rem 1.5rem 3rem;
  color: ${p => p.theme.footerTextColor};
  background-color: ${p => p.theme.footerBgColor};
`;

const SubFooter = styled.footer`
  padding: 0.75em;
  font-size: 0.75rem;
  background-color: ${p => p.theme.subFooterBgColor};
  color: ${p => p.theme.subFooterTextColor};
`;

const Title = styled.h2`
  /* font-weight: 700 !important; */
  font-size: 1.5rem !important;
  margin-bottom: 1em !important;
  font-family: ${p => p.theme.headerFontFamily};
`;

const Social = styled.span`
  margin-bottom: 0.66em;
  font-size: 1em;
  position: relative;
  display: block;
`;

const icon = css`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const SocLink = styled(Link)`
  line-height: 1.5;
  padding-left: 2em;
`;
const linkStyle = css`
  margin-bottom: 0.66em;
`;

const Privacy = styled(Link)`
  margin-left: 2em;
`;

const Imprint = styled(Link)`
  ${p =>
    p.theme.minWidth.tablet(css`
      margin-left: 2em;
    `)}
`;

const query = graphql`
  query ContactQuery {
    markdownRemark(frontmatter: { title: { eq: "Kontakt" } }) {
      frontmatter {
        title
        phone
        email
        address {
          street
          city
        }
        social {
          facebook
          instagram
        }
      }
    }
  }
`;

const Footer = () => {
  const { markdownRemark } = useStaticQuery(query);

  const {
    title,
    phone,
    email,
    address: { street, city },
    social: { facebook, instagram }
  } = markdownRemark.frontmatter;

  return (
    <>
      <MainFooter>
        <div className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-3">
                <Title>Folgen Sie uns</Title>
                <Social>
                  <Facebook css={icon} size={20} />
                  <SocLink to={facebook}>Facebook</SocLink>
                </Social>
                <Social>
                  <Instagram css={icon} size={20} />
                  <SocLink to={instagram}>Instagram</SocLink>
                </Social>
              </div>
              <div className="column is-3">
                <Title>Navigation</Title>
                {menu.map(({ label, to }) => (
                  <Link css={linkStyle} key={label} to={to} activeClassName="is-active">
                    {label}
                  </Link>
                ))}
              </div>
              <div className="column is-narrow">
                <ContactInfo
                  footer
                  title={title}
                  phone={phone}
                  email={email}
                  street={street}
                  city={city}
                />
              </div>
            </div>
          </div>
        </div>
      </MainFooter>

      <SubFooter>
        <div className="container level">
          <div className="level-item has-text-centered">
            <div className="level">
              <div className="level-item">{copyright}</div>
              <div className="level-item">
                <Imprint to="/impressum">Impressum</Imprint>
                <Privacy to="/datenschutzerklarung">Datenschutzerklärung</Privacy>
              </div>
            </div>
          </div>
          <div className="level-item has-text-centered">
            Entwickler:&nbsp;<Link to={authorLink}>{author}</Link>
          </div>
        </div>
      </SubFooter>
    </>
  );
};

export default Footer;
