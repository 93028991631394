import OpenSansRegularTtf from './openSans/OpenSans-Regular.ttf';
import OpenSansRegularWoff from './openSans/OpenSans-Regular.woff';
import OpenSansRegularWoff2 from './openSans/OpenSans-Regular.woff2';
import OpenSansSemiBoldTtf from './openSans/OpenSans-SemiBold.ttf';
import OpenSansSemiBoldWoff from './openSans/OpenSans-SemiBold.woff';
import OpenSansSemiBoldWoff2 from './openSans/OpenSans-SemiBold.woff2';

import CabinSemiBoldTtf from './cabin/Cabin-SemiBold.ttf';
import CabinSemiBoldWoff from './cabin/Cabin-SemiBold.woff';
import CabinSemiBoldWoff2 from './cabin/Cabin-SemiBold.woff2';

export default {
  OpenSansRegularTtf,
  OpenSansRegularWoff,
  OpenSansRegularWoff2,
  OpenSansSemiBoldTtf,
  OpenSansSemiBoldWoff,
  OpenSansSemiBoldWoff2,

  CabinSemiBoldTtf,
  CabinSemiBoldWoff,
  CabinSemiBoldWoff2
};
