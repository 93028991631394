const base = {
  name: 'Marijan & Novovic GbR', // Site title.
  url: 'https://marijan-novovic.de', // Domain of your website. No trailing slash!
};

module.exports = {
  /* meta tags */
  siteTitle: `Trockenbau | ${base.name}`,
  siteTitleAlt: base.name, // Title for JSONLD.
  siteTitleTemplate: `%s | ${base.name}`, // Useful when you want titles to inherit from a template
  siteDescription:
    'Sie wollen sich in Ihrem Zuhause verändern? Als Trockenbau-Team bieten wir Ihnen umfangreiche Dienstleistungen für Ihre Umgestaltung an.',
  siteHeadline: 'Trockenbau', // Headline for schema.org JSONLD

  /* url */
  siteUrl: base.url, // Domain of your site. No trailing slash!
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-example/.

  siteLogo: '/logos/logo.png', // Logo used for SEO.
  siteLanguage: 'de', // Language Tag on <html> element

  /* social */
  twitter: '@trockenbaumn', // Twitter Username
  facebook: 'trockenbaumn', // Facebook Site Name
  ogLanguage: 'de', // Facebook Language

  googleAnalyticsID: '', // googleAnalyticsID gtag tracking ID.
  fbAppID: '', // FB Application ID for using app insights

  /* manifest */
  manifestName: base.name,
  manifestShortName: 'M|N GbR', // shortname for manifest. MUST be shorter than 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#293541', // Used for setting manifest background color.
  manifestThemeColor: '#272727', // Used for setting manifest and progress theme colors.
  manifestDisplay: 'standalone',
  manifestIcon: 'static/logos/icon.png',

  /* author */
  author: 'Darkwood.Dev', // Author for schemaORGJSONLD
  authorLink: 'https://darkwood.dev',

  /* copyright */
  copyright: `© 2019 ${base.name}`, // Copyright string for the footer of the website and RSS feed.

  /* menu links */
  menu: [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/uber-uns',
      label: 'Über uns',
    },
    {
      to: '/leistungen',
      label: 'Leistungen',
    },
    {
      to: '/galerie',
      label: 'Galerie',
    },
    {
      to: '/kontakt',
      label: 'Kontakt',
    },
  ],
};
