import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import ThemeContext from '../../../store/createContext';
import { grayscale, shadow } from '../../../utils/colors';
import { menu } from '../../../../config/website';
import NavbarLink from './NavbarLink';
import { Moon, Sun } from './Icons';

const Overlay = styled.div`
  background: ${rgba(grayscale('dark'), 0.8)};
  opacity: 0;
  height: 100vh;
  width: 100vw;
  transition: opacity 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -2;
  margin-top: ${p => p.theme.navbarHeight};

  ${p =>
    p.isOpen &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}

  ${p =>
    p.theme.minWidth.tablet(css`
      display: none;
    `)}
`;

const Nav = styled.nav`
  transition: background 0.25s, box-shadow 0.25s, 0.2s cubic-bezier(0.1, 1, 0.4, 1);
  box-shadow: ${shadow('base')};
  background-color: ${p => p.theme.bgHeader};
  ${p =>
    p.theme.minWidth.tablet(css`
      min-height: ${p.theme.navbarHeight};
    `)}
`;

const NavMenu = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  visibility: hidden;
  z-index: -1;
  transform: translateY(0%);
  transition: 0.2s cubic-bezier(0.1, 1, 0.4, 1);
  background-color: inherit;

  ${p =>
    p.isOpen &&
    css`
      transform: translateY(100%);
      visibility: visible;
    `}

  ${p =>
    p.theme.minWidth.tablet(css`
      z-index: 0;
      transform: translateY(0%);
      visibility: visible;
    `)}
`;

const Container = styled.div`
  ${p =>
    p.theme.minWidth.tablet(css`
      background-color: transparent;
    `)}
  background-color: inherit;
`;

const IconButton = styled.button`
  position: relative;
  padding-left: ${p => p.theme.space[3]}px;
  padding-right: ${p => p.theme.space[4]}px;
  color: ${p => p.theme.headingColor};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 0;
  outline: 0;
  overflow: hidden;
  cursor: pointer;
  font-weight: 700;
`;

const Light = styled(Sun)`
  ${p =>
    p.theme.minWidth.tablet(css`
      position: absolute;
      left: ${p.theme.space[2]}px;
    `)}
  ${p =>
    p.light
      ? css`
          position: absolute;
          opacity: 1;
          transform: rotate(0deg);
        `
      : css`
          opacity: 0;
          transform: rotate(360deg);
        `};
  transition: opacity 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s,
    transform 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
`;

const Dark = styled(Moon)`
  ${p =>
    p.theme.minWidth.tablet(css`
      position: absolute;
      left: ${p.theme.space[2]}px;
    `)}
  ${p =>
    p.light
      ? css`
          opacity: 0;
          transform: rotate(360deg);
        `
      : css`
          position: absolute;
          opacity: 1;
          transform: rotate(0deg);
        `};
  transition: opacity 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s,
    transform 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
`;

const BrandLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${p => p.theme.headingColor};
`;

const BrandText = styled.h2`
  font-size: 1.5em;
  font-family: ${p => p.theme.headerFontFamily};
`;

export default () => {
  const [navbarState, setNavbarState] = useState(false);

  const { themeStyle, toggleTheme } = useContext(ThemeContext);

  const toggleState = (newState = !navbarState) => setNavbarState(newState);

  return (
    <Nav className="navbar is-fixed-top" role="navigation" aria-label="main-navigation">
      <Container className="container">
        <div className="navbar-brand">
          <BrandLink
            to="/"
            className="navbar-item"
            title="Logo"
            style={{ zIndex: 10 }}
            onClick={() => setNavbarState(false)}
          >
            {/* <img src={logo} alt="Logo" style={{ marginRight: '3px' }} /> */}
            <BrandText>M|N</BrandText>
          </BrandLink>
          <a
            onClick={() => toggleState()}
            className={`navbar-burger burger ${navbarState ? 'is-active' : ''}`}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <NavMenu isOpen={navbarState} className={`navbar-menu ${navbarState ? 'is-active' : ''}`}>
          <div className="navbar-end" onClick={() => setNavbarState(false)}>
            {menu.map(({ label, to }) => (
              <NavbarLink key={label} to={to} className="navbar-item" activeClassName="is-active">
                {label.toUpperCase()}
              </NavbarLink>
            ))}
            <IconButton className="navbar-item" onClick={toggleTheme}>
              <Light light={(themeStyle === 'light').toString()} />
              <Dark light={(themeStyle === 'light').toString()} />
            </IconButton>
          </div>
        </NavMenu>
      </Container>
      <Overlay isOpen={navbarState} onClick={() => toggleState()} />
    </Nav>
  );
};
