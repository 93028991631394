import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const Title = styled.div`
  font-family: ${p => p.theme.headerFontFamily};
  background-color: #549748;
  font-size: 2rem;
  color: white;
  line-height: 1.125;
  padding: 1rem 1.5rem;
  position: absolute;
  ${p =>
    p.theme.minWidth.tablet(css`
      font-size: 3em;
    `)}
`;

const BanerTitle = ({ children }) => <Title>{children}</Title>;

BanerTitle.propTypes = {
  children: PropTypes.string.isRequired
};

export default BanerTitle;
