import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

const StyledNavbarLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${p => p.theme.textColor};
  text-decoration: none;
  transition: color 0.2s;
  &:hover {
    color: ${p => p.theme.onHoverLink};
  }
  &:last-child {
    padding-right: 0;
  }
`;

const NavbarLink = ({ to, children, theme }) => (
  <StyledNavbarLink to={to} itemProp="url" activeStyle={{ color: theme.activeLink }}>
    <span itemProp="name">{children}</span>
  </StyledNavbarLink>
);

NavbarLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  theme: PropTypes.instanceOf(Object).isRequired
};

export default withTheme(NavbarLink);
